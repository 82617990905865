<template>
    <v-card class="mb-5">
        <v-toolbar color="primary" dark elevation="3" class="scan-title-bar">
            <v-toolbar-title class="text-section-title">{{ title }}</v-toolbar-title>
        </v-toolbar>
        <div class="w-full px-3 image-grid-section" align="center">
            <v-card elevation="3" class="ma-2" v-for="(damage, index) in damages" :key="`damage-${index}-${damage.id}`">
                <v-lazy>
                    <img height="100" width="100%" :id="`annoatate-${index}-${damage.id}`" :src="damage.src"
                        crossorigin="anonymous"/>
                </v-lazy>
                <v-card-actions class="pa-0 justify-center">
                    <v-btn small icon color="primary" @click="show(index)" class="ml-0">
                        <v-icon small>mdi-archive-eye-outline</v-icon>
                    </v-btn>
                    <v-btn small icon color="primary" @click="openCrop = damage.src" class="ml-0">
                        <v-icon small>mdi-crop</v-icon>
                    </v-btn>
                    <v-btn small icon color="primary" @click="showMarkerArea(`annoatate-${index}-${damage.id}`)" class="ml-0">
                        <v-icon small>mdi-image-plus</v-icon>
                    </v-btn>
                    <v-btn small icon color="primary" class="ml-0" @click="handleInfo(damage)">
                        <v-icon small>mdi-information</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </div>
        <CropperModal :openCrop="openCrop" v-if="openCrop" @cancel="openCrop = null"
            @cropped="$emit('updated', $event); openCrop = null" :name="'Cropped'"/>
        <InfoModal :visible="infoModal.visible" v-if="infoModal.visible" @close="infoModal = {visible: false, selected: null}"
            :selected="infoModal.selected"/>
    </v-card>
</template>

<script>
    import * as markerjs2 from "markerjs2";
    import _ from 'lodash';

    export default {
        props: {
            damages: {
                type: Array,
                default: () => ([])
            },
            title: {
                type: String,
                default: 'Dents'
            }
        },
        components: {
            CropperModal: () => import("../CropperModal"),
            InfoModal: () => import("./InfoModal.vue"),
        },
        data () {
            return {
                openCrop: null,
                infoModal: {
                    visible: false,
                    selected: null
                }
            }
        },
        methods: {
            show(index) {
                this.$viewerApi({
                    options: {
                        initialViewIndex: index
                    },
                    images: _.map(this.damages, 'src')
                })
            },
            showMarkerArea(imgId) {
                const target = document.getElementById(imgId);
                const markerArea = new markerjs2.MarkerArea(target);
                markerArea.uiStyleSettings.zoomOutButtonVisible = true;
                markerArea.uiStyleSettings.zoomButtonVisible = true;
                markerArea.renderImageType = 'image/jpeg';
                markerArea.settings.displayMode = "popup";
                markerArea.addEventListener("render", (event) => {
                    this.$store.commit('setPageLoading', false);
                    this.$emit('updated', {value: event.dataUrl, option: 'Annotated'});
                });
                markerArea.show();
            },
            handleInfo(damage) {
                this.infoModal.visible = true;
                this.infoModal.selected = damage;
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>